import React, { Component } from "react"
import "styles/pages/resume.scss"

// Components
import Layout from "components/Layout"
import SEO from "components/SEO/"

class Resume extends Component {

  render() {
    return (
      <Layout pathname="resume">
        <SEO title="Resume" pathname={this.props.location.pathname} />
        <div className="resume-page container">
          <div className="segment about">
            <p className="title">Naufal Aros</p>
            <div className="content">
              <p>Passionate engineer based in California. Currently, I'm focused on creating engaging and data driven experiences for web and native mobile applications utilizing the design-thinking process.</p>
            </div>
          </div>
          <div className="segment skills">
            <p className="title">Skills</p>
            <div className="content">
              <p>Mobile design, web design, interaction/experience design, visual design, and product strategy.  Mainly designing with Sketch + Figma these days, and prototyping in Origami.</p>
              <p>Experienced in designing for both iOS and Android platforms.</p>
              <p>Professionally fluent in Chinese.</p>
            </div>
          </div>
          <div className="segment experience">
            <p className="title">Experience</p>
            <div className="content">
              <div className="work">
                <div className="company-container">
                  <p className="company">eDreams ODIGEO</p>
                  <p className="location">Madrid, Spain & Remote</p>
                </div>
                <div className="role-container">
                  <p className="role">iOS Software Engineer</p>
                  <p className="date">Jul 2019 - Present</p>
                </div>
                <div className="description">
                  <p>I’m part of the Booking team, where we design and build experiences to make the flight booking more enjoyable.</p>
                </div>
              </div>
              <div className="work">
                <div className="company-container">
                  <p className="company">Liquid Squad (Accenture)</p>
                  <p className="location">Madrid, Spain</p>
                </div>
                <div className="role-container">
                  <p className="role">iOS Engineer</p>
                  <p className="date">Jan 2018 - Jul 2019</p>
                </div>
                <div className="description">
                  <p>Engineer on the mobile team at Liquid Squad, the digital studio acquired by Accenture. We were a team of 15 developers working on projects for clients in fintech, e-commerce and healthcare. In this role, I focused on the design, development and distribution of iOS apps where I contributed to all aspects of the apps, from infrastructure on the CI/CD pipelines, networking, persistence all the way up to UI/UX.</p>
                  <p>Although I mostly worked on the mobile team, I was also part of the R&D team. Here, we explored new opportunities to help our clients by coming up with new IoT solutions and combining them with a mobile ecosystem. My work consisted of designing and implementing hardware solutions and building mobile apps to integrate them.</p>
                </div>
              </div>
              <div className="work">
                <div className="company-container">
                  <p className="company">Tecnilogica</p>
                  <p className="location">Madrid, Spain</p>
                </div>
                <div className="role-container">
                  <p className="role">iOS Engineer Intern</p>
                  <p className="date">Summer 2017</p>
                </div>
                <div className="description">
                  <p>At Tecnilogica, I worked on the mobile team designing and delivering new features for one of our fintech projects by collaborating closely with backend engineers and the design team. I had the chance to introduce Swift into the project and design dev tools to speed up the development of the iOS team.</p>
                  <p>We were acquired by Accenture to later become Liquid Squad!</p>
                </div>
              </div>
            </div>
          </div>
          <div className="segment education">
            <p className="title">Education</p>
            <div className="content">
              <p className="college">University of Valladolid, Spain</p>
              <p className="studies bachelor">Bachelor's Degree in Computer Engineering.</p>
              <p className="studies minor">Minor in Computer Science.</p>
              <p className="date">2014 - 2018</p>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Resume